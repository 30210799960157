<template>
  <div>
    <MultiSelect :class="`multiSelect ${!isValid ? 'error' : ''} ${disabled ? 'disabled' : ''} wrapper`"
      :modelValue="modelValue" @update:modelValue="$emit('update:modelValue', $event)" :disabled="disabled"
      :options="options" optionLabel="name" :placeholder="placeholder" :maxSelectedLabels="15" display="chip">
    </MultiSelect>
    <div class="v-input__details">
      <div v-if="!isValid" class="v-messages">
        <div class="v-messages__message">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';

import 'vue-select/dist/vue-select.css';


export default {
  name: 'UiVueMultiSelect',

  components: {
    MultiSelect
  },
  props: {
    modelValue: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    }
  },
};
</script>
<style scoped>
.multiSelect {
  border-radius: 0 !important;
  padding: 7px !important;
  font-family: "Roboto Condensed", sans-serif !important;
  border: 1px solid rgb(180, 180, 180) !important;
  color: #000 !important;
}

.multiSelect:hover,
.multiSelect:focus {
  border: 1px solid rgb(118, 118, 118) !important;
}

.error,
.error:hover,
.error:focus {
  border: 1px solid #b00020 !important;
}

.disabled,
.disabled:hover,
.disabled:focus {
  background-color: rgb(243, 243, 243) !important;
  border: 1px solid rgb(180, 180, 180) !important;
  cursor: not-allowed;
}
</style>