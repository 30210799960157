const dev = process.env.MODE !== 'production';
const baseUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost/identity'
  : '/identity';
const keycloakRealm = 'spindverwaltung';
const keycloakClient = 'spindverwaltung-frontend';

module.exports = {
  dev,
  isAuthEnabled: true,
  keycloak: {
    realm: keycloakRealm,
    'auth-server-url': baseUrl + '/auth',
    url: baseUrl,
    'ssl-required': 'external',
    resource: `${keycloakRealm}/keep it default`,
    'public-client': true,
    'confidential-port': 0,
    clientId: keycloakClient,
    'enable-cors': true,
    scope: 'openid profile'
  },
};
