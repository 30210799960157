<template>
  <div v-if="alert" class="api-tracker">
    <span class="api-tracker__alert">{{ alert }}</span>
  </div>
</template>
<script>
import { healthCheck } from '@/apiClient';
import apiConfig from '@/apiClient/config.js';

export default {
  data() {
    return {
      alert: null
    };
  },
  mounted() {
    setInterval(() => {
      healthCheck()
        .then((isHealthy) => {
          if (isHealthy) {
            this.alert = null;
          }
          else {
            this.alert = 'Der Server ist nicht verfügbar';
          }
        });
    }, apiConfig.healthCheckInterval);
  }
};
</script>

<style>
.api-tracker {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  background-color: #000;
  opacity: 0.95;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  overflow: hidden;
}
</style>