<template>
  <div class="wrapper" data-device="Bitte verwenden Sie ein Endgerät mit einem größeren Bildschirmformat">
    <router-view />
  </div>
</template>

<script>

import ApiTracker from '@/components/ApiTracker';

export default {
  components: {
    ApiTracker
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

.router-link-exact-active {
  background-color: #005b78;
}
</style>
