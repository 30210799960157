<template>
  <div>
    <vueSelect :class="{ 'v-select--invalid': !isValid }" class="style-chooser" :clearable="clearable" :options="options"
      :label="label" :reduce="reduce" :modelValue="modelValue" @update:modelValue="$emit('update:modelValue', $event)"
      @open="open" @search="search" :disabled="disabled">
      <template #open-indicator>
        <i class="v-icon notranslate v-theme--light v-icon--size-default" aria-hidden="true">
          <img v-if="isLoading" src="../../assets/svg/ico_loader.svg" alt="">
          <svg v-else class="v-icon__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img"
            aria-hidden="true">
            <path d="M7,10L12,15L17,10H7Z"></path>
          </svg>
        </i>
      </template>
      <template v-slot:no-options>Nichts hier.</template>
    </vueSelect>
    <div class="v-input__details">
      <div v-if="!isValid" class="v-messages">
        <div class="v-messages__message">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'UiVueSelect',
  components: {
    vueSelect,
  },
  props: {
    modelValue: {
      required: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      required: true
    },
    reduce: {
      type: Function,
      required: true
    },
    isValid: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
    }
  },
  methods: {
    search() {
      this.$emit.apply(this, ['search', ...arguments]);
    },
    open() {
      this.$emit.apply(this, ['open', ...arguments]);
    }
  }
};
</script>
