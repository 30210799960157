<template lang="">
   <div class="dialog" @wheel.prevent
 @touchmove.prevent
 @scroll.prevent>
         <div class="dialog__content">
            <img width="30" src="../../assets/svg/ico_loader.svg" alt="">
            <!-- Wird geladen... -->
         </div>
   </div>
</template>
<script>
export default {
   name: 'UiLoader'
}
</script>
<style lang="css" scoped>
.dialog {
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background: rgba(255, 255, 255, 0.5);
   position: fixed;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 100;
}

.dialog__content {

   /* margin: auto; */
   /* background: white; */
   /* border-radius: 12px; */
   min-height: 50px;
   min-width: 50px;
   z-index: 101;
   color: white;
   /* padding: 20px; */
}
</style>