import Keycloak from 'keycloak-js';
import config from '../../config.js';

const keycloakInstance = new Keycloak(config.keycloak);
const updateToken = store => {
  keycloakInstance
    .updateToken(70)
    .then(refreshed => {
      if (refreshed) {
        updateStore(store);
      }
    })
    .catch(e => {
      console.warn('Failed to refresh token.', e);
    });
};
const updateStore = store => {

  store.commit('setAuthInfo', {
    token: keycloakInstance.token,
    roles: keycloakInstance.realmAccess.roles,
  });
};
let updateTokenInterval;

export default {
  login: (loginCallback, store) => {
    keycloakInstance
      .init({
        onLoad: 'login-required',
        redirectUri: window.location.href.replace(/^(.+?)(\?*)$/, '$1') + '?',
      })
      .then(function (authenticated) {
        if (authenticated) {
          updateStore(store);
          loginCallback(keycloakInstance);

          if (!updateTokenInterval)
            updateTokenInterval = setInterval(updateToken, 6000, store);
        } else {
          window.location.reload();
        }
      })
      .catch(e => {
        console.dir(e);
        console.log(`keycloak init exception: ${e}`);
      });
  },
  logout: () => keycloakInstance.logout(),
};
