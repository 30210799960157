import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import globalVariables from './modules/globalVariables';

let savedDebtorIds = localStorage.getItem('savedDebtorIds');
savedDebtorIds = savedDebtorIds ? JSON.parse(savedDebtorIds) : [];

export default createStore({
  state: {
    debtorIds: savedDebtorIds,
    authInfo: {},
  },

  mutations: {
    setDebtorIds(state, debtorIds) {
      state.debtorIds = debtorIds;
      localStorage.setItem('savedDebtorIds', JSON.stringify(debtorIds));
    },
    setAuthInfo(state, authInfo) {
      state.authInfo = authInfo;
      localStorage.setItem('token', authInfo.token);
    },
  },

  getters: {},

  actions: {},

  modules: {
    globalVariables,
  },

  plugins: [
    createPersistedState({
      paths: ['globalVariables'],
    }),
  ],
});
