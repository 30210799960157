export default {
  // namespaced: true,
  state: () => ({
    requestsTotal: 0,
    requestsCurrentPage: 1,
    requestsLimit: 10,
  }),
  mutations: {
    setRequestsTotal(state, total) {
      state.requestsTotal = total;
    },
    setRequestsCurrentPage(state, page) {
      state.requestsCurrentPage = page;
    },
    setRequestsLimit(state, limit) {
      state.requestsLimit = limit;
    },
  },
  getters: {
    getRequestsTotal(state) {
      return state.requestsTotal;
    },
    getRequestsCurrentPage(state) {
      return state.requestsCurrentPage;
    },
    getRequestsLimit(state) {
      return state.requestsLimit;
    },
  },
};
